<template>
  <div class="sign-page">
    <person-head></person-head>
    <div class="sign-main">
      <div class="personage_head">
        <div class="personage_head-s">
          <div class="head-li w-160">
            <el-select v-model="from.name" filterable remote clearable @change="changePlot" :remote-method="remoteMethod"
              placeholder="请选择">
              <el-option v-for="item in plotList" :key="item.id" :label="item.name" :value="item.name">
              </el-option>
            </el-select>
          </div>
          <div class="head-line"></div>
          <div class="head-li w-68">
            <el-input v-model="from.dongs" placeholder="楼号"></el-input>
          </div>
          <div class="head-tip">楼号</div>
          <div class="head-line"></div>
          <div class="head-li w-68">
            <el-input v-model="from.danyuans" placeholder="单元"></el-input>
          </div>
          <div class="head-tip">单元</div>
          <div class="head-line"></div>
          <div class="head-li w-68">
            <el-input v-model="from.menpais" placeholder="门牌号"></el-input>
          </div>
          <div class="head-tip m-r-40">室</div>
          <div class="head-li">
            <el-button class="build-head-btn" @click="screen">搜索</el-button>
          </div>
        </div>
        <div class="personage_head_top">
          <div class="personage_head_txt">类型:</div>
          <ul class="personage_head_ul">
            <li @click="changeType('fang_types', item.value)" :class="{ active: from.fang_types == item.value }"
              v-for="(item, index) in getZuHousingTypeList()" :key="index">
              {{ item.label }}
            </li>
          </ul>
        </div>
      </div>
      <!--rate_page_list start-->
      <div class="rate_page_list">
        <ul class="personage_ul">
          <!--li start-->
          <li @click="hrefDetail(item)" v-for="(item, index) in list" :key="index">
            <div class="personage_ul_img m-r-20">
              <img :src="item.fengmian_image" alt="" />
              <div class="personage_img_marking">{{ item.fang_level }}</div>
            </div>
            <div class="personage_ul_main f-1">
              <div class="personage_ul_name">{{ item.name }}</div>
              <div class="personage_ul_title"> 合同期限：{{ item.start_time }}至{{ item.end_time }}</div>
              <div class="personage_ul_price flex-between">
                <div class="flex">
                  <div class="personage_ul_price_left m-r-20">
                    <span>{{ item.price }}元/月</span>
                  </div>
                </div>
              </div>
              <div class="flex flex-between">
                <div class="tip color_orange" :class="{ color_blue: item.status == 1 }">{{ getStatus(item.status) }}</div>
                <div class="personage_ul-bot">
                  <button @click.stop="changeItem(0, item)" class="rate_page_btn red"
                    v-if="item.status == 5">终止合作</button>
                  <button @click.stop="changeItem(1, item)" class="rate_page_btn blue"
                    v-if="item.status == 1 && item.baogao_status == 0">成交报告</button>
                  <button @click.stop="changeItem(2, item)" class="rate_page_btn"
                    v-if="item.status == 2 || item.status == 3">查看详情</button>
                </div>
              </div>
            </div>
          </li>
          <!--li end-->
        </ul>
      </div>
      <!--rate_page_list end-->
      <div class="paging" v-if="list.length">
        <el-pagination background @current-change="changePage" layout="prev, pager, next, jumper, ->, total"
          :total="total">
        </el-pagination>
      </div>
    </div>
    <sign-pop ref="report"></sign-pop>
  </div>
</template>

<script>
import PersonHead from "../../components/PersonHead";
import signPop from "../../components/SignPop.vue"
import {mapGetters} from "vuex";
export default {
  components: { PersonHead, signPop },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  data() {
    return {
      total: 0, //条数
      list: [], //列表
      from: {
        fang_types: 2, //1=新房2=住宅3=公寓4=写字楼5=商铺6=别墅
        fang_type: 2, //房源类型：1=出售2=出租(新房默认1
        page: 1, //页数
        type: 2,
        dongs:'',
        danyuans:'',
        menpais:'',
        name:''
      },
      plotList:[],//小区列表
    };
  },
  methods: {
    //
    changeItem(index, item) {
      if (index == 0) {
        this.$axios.termination({ id: item.id }).then(res => {
          console.log(res)
          this.$message({
            message: res.msg,
            type: 'success'
          });
          this.from.page = 1
          this.getSignAContract();
        })
      } else if (index == 1) {
        this.$refs.report.open(item.id, item.fang_type, item.fang_types)
      } else if (index == 2) {
        this.$router.push({ path: "/personage/signDetail", query: { id: item.id, fang_type: item.fang_type, fang_types: item.fang_types } });
      }
    },
    //分页
    changePage(e) {
      this.from.page = e
      this.getSignAContract();
    },
    //切换类型
    changeType(type, value) {
      if (this.from[type] == value) return
      this.from[type] = value
      this.init();
    },
    init() {
      this.list = [];
      this.from.page = 1;
      this.getSignAContract();
    },
    //跳转页面
    hrefDetail(item) {
      this.$router.push({ path: "/personage/signDetail", query: { id: item.id, fang_type: item.fang_type, fang_types: item.fang_types } });
    },
    //获取列表
    getSignAContract() {
      this.$axios.SignAContract(this.from).then((res) => {
        this.list = res.data.data;
        this.total = res.data.total;
      });
    },
    remoteMethod(e) {
      this.$axios
        .xiaoQuList({
          letter: e,
          pageNum: 10000,
        })
        .then((res) => {
          this.plotList = this.getFTPrivilegeXiaoQuList(res.data.data);
        });
    },
    screen() {
      this.from.page = 1;
      this.getSignAContract();
    },
    //切换小区
    changePlot () {
      this.from.dongs = ''
      this.from.danyuans = ''
      this.from.menpais = ''
    },
    getStatus(status) {
      let txt = ''
      switch (status) {
        case 0:
          txt = '待租客确认'
          break;
        case 1:
          txt = '签约成功'
          break;
        case 2:
          txt = '交易失败'
          break;
        case 3:
          txt = '已完成'
          break;
        case 5:
          txt = '待业主绑定收款账号并签字'
          break;
        case 6:
          txt = '待租客上传打款凭证并签字'
          break;
        case 7:
          txt = '待业主上传收款收据'
          break;
        default:
          break;
      }
      return txt
    }
  },
  created() {
    if ((this.userInfo.position === 3 || this.userInfo.position === 4) && this.userInfo.fang_type_privilege === 1) this.from.fang_types = 4
    this.remoteMethod("");
    this.getSignAContract();
  },
};
</script>

<style scoped lang="less">
.personage_head {
  padding: 30px 30px;
  border-bottom: 1px solid #ebebeb;

  .personage_head-s {
    display: flex;
    // justify-content: center;
    align-items: center;
    // padding-bottom: 35px;
    // max-width: 1200px;
    // margin: 0 auto;

    .w-120 {
      width: 120px;
    }

    .w-160 {
      width: 160px;
    }

    .w-68 {
      width: 68px;
    }

    .head-line {
      width: 20px;
      height: 2px;
      background: #ccc;
      margin: 0 6px;
    }

    .head-tip {
      font-size: 18px;
      margin-left: 5px;
    }

    /deep/ .el-input__inner {
      height: 48px;
      line-height: 48px;
      font-size: 18px;
    }

    .build-head-btn {
      width: 100px;
      height: 48px;
      background: #3273f6;
      font-size: 18px;
      color: #fff;
    }
  }

  .personage_head_top {
    display: flex;

    &:last-child {
      margin-top: 15px;
    }

    .personage_head_txt {
      margin-right: 30px;
      width: 70px;
      color: #333333;
      font-size: 16px;
      font-weight: bold;
    }

    .personage_head_ul {
      display: flex;
      flex-wrap: wrap;

      li {
        cursor: pointer;
        color: #333333;
        font-size: 16px;
        margin-right: 40px;

        &:last-child {
          margin-right: 0;
        }
      }

      .active {
        color: #3273f6;
        font-weight: bold;
      }
    }
  }
}

.sign-main {
  background: #fff;
  padding: 0 20px;
}

.rate_page_btn {
  width: 112px;
  height: 40px;
  border: 1px solid #3273f6;
  border-radius: 4px;
  color: #3273f6;
  font-size: 16px;
  text-align: center;
  line-height: 40px;
  background: #fff;
  outline: none;
  cursor: pointer;
}

.rate_page_btn.orange {
  background: #ffa740;
  border: 1px solid #ffa740;
  color: #fff;
}

.rate_page_btn.blue {
  background: #3273f6;
  border: 1px solid #3273f6;
  color: #fff;
}

.rate_page_btn.red {
  background: #fddfdf;
  border: 1px solid #fddfdf;
  color: #ff5151;
}

.personage_ul {
  padding: 0 30px;

  li {
    padding: 30px 0px;
    display: flex;
    border-bottom: 1px solid #ebebeb;

    .tip {
      font-size: 14px;
    }

    .color_orange {
      color: #FF9600;
    }

    .color_blue {
      color: #3273F6;
    }

    .personage_ul_img {
      img {
        width: 220px;
        height: 165px;
        border-radius: 8px;
      }

      position: relative;

      .personage_img_marking {
        top: 0;
        left: 0;
        position: absolute;
        width: 56px;
        height: 32px;
        color: #ffffff;
        font-size: 18px;
        text-align: center;
        line-height: 32px;
        background: rgba(0, 0, 0, 0.3);
        border-radius: 8px 0px 8px 0px;
      }
    }

    .personage_ul_main {
      height: 165px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .personage_ul_name {
        color: #333333;
        font-size: 18px;
        font-weight: bold;
      }

      .personage_ul_title {
        color: #999999;
        font-size: 14px;
      }

      .personage_ul_price {
        display: flex;
        align-items: flex-end;

        .personage_ul_price_left {
          display: flex;
          align-items: center;

          span {
            color: #ff2121;
            font-size: 22px;
            font-weight: bold;
          }
        }

        .personage_ul_price_right {
          display: flex;
          align-items: center;

          span {
            color: #999999;
            font-size: 18px;
          }
        }

        img {
          width: 11px;
          height: 14px;
          margin-left: 10px;
        }
      }

      .personage_ul_footer {
        width: 100%;
        align-items: flex-end;

        .personage_ul_footer_phone {
          width: 36px;
          height: 36px;
          background: #ffe7e3;
          border-radius: 50%;
        }

        .personage_ul_footer_img {
          img {
            width: 48px;
            height: 48px;
            border-radius: 50%;
          }

          margin-right: 10px;
        }

        .personage_ul_footer_main {
          height: 48px;
          align-items: flex-start;
          flex-direction: column;
        }

        .personage_ul_footer_name {
          color: #333333;
          font-size: 16px;
          font-weight: bold;
        }

        .personage_ul_footer_time {
          span {
            color: #ff9600;
            font-size: 14px;

            &:first-child {
              margin-right: 40px;
            }
          }
        }
      }
    }
  }
}</style>
